<template>
  <PSelect    
    :items="locations"    
    :value="value"
    :focus="focus"
    :required="required"
    @input="onInput"
    itemText="name"
    itemValue="key"
    v-bind="$props"    
    v-slot="{ item }"      
  >
    {{ item.name }}
  </PSelect>
</template>

<script>

import http from "@/http"
import { mapGetters } from "vuex"
import { concat } from "lodash"

export default {

  name: "PExternalLocationSelect",
  
  props: {    
    value: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    error: {
      type: String,
      default: ""
    },
    searchable: {
      type: Boolean,
      default: false
    },
    focus: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  
  data () {
    return {
      locations: [],
      requestFailed: false
    }
  },
  
  computed: {    
    ...mapGetters([
      "customer", 
      "location"
    ]),
  },

  methods: {

    async init () {
      
      let promises = []

      promises.push(http.get("ExternalLocations", {
        params: {
          customerId: this.customer.id,
          isShop: false
        }
      }))

      if (this.location.allowShopTransactions && this.location.poolingGroupId) {
        promises.push(http.get("ExternalLocations", {
          params: {
            poolingGroupId: this.location.poolingGroupId,
            isShop: true
          }
        }))
      }

      try {
        
        const response = await Promise.all(promises)
        
        this.locations = response.length === 1 ? response[0] :
                         response.length === 2 ? concat(response[0], response[1]) : []
        
      } catch {
        this.requestFailed = true
      }

    },

    onInput (value) {
      this.$emit("input", value)
    }
  },
  
  async created () {
    await this.init()
  }

}
</script>