<template>
  <PDialog
    classes="order-dialog"
    :title="$tk('ExternalOrderDialog.ExternalDelivery')"
    :loading="isLoading"
    @close="$emit('close')"
  >
    <div class="flex-1 md:overflow-y-hidden shadow-inner bg-gray-100">

      <div class="flex flex-col md:flex-row md:h-full md:overflow-hidden">

        <div class="order-dialog-header md:overflow-y-auto p-4 border-r">

          <div class="flex space-x-4">
            <div class="w-1/2">

              <PDatePicker
                :label="$tk('Common.Actions.Sent')"                
                v-model="order.sendDate"
                :disabled="isUpdating"
              />

            </div>
          </div>
          <div class="mt-4">

            <PExternalLocationSelect
              :required="true"
              :label="$tk('Common.General.To')"
              v-model="order.externalLocationKey"              
              :disabled="isUpdating"
            />

          </div>
          <div class="mt-4 flex space-x-4">
            <div class="w-1/2">

              <PInput 
                :label="$tk('Common.General.SendersReference')"                
                v-model="order.sendersRef" 
                :disabled="isUpdating" 
              />
              
            </div>
            <div class="w-1/2">

              <PInput 
                :label="$tk('Common.General.ReceiversReference')"                
                v-model="order.receiversRef" 
                :disabled="isUpdating" 
              />

            </div>
          </div>
          <div class="mt-4">

            <PTextarea              
              :label="$tk('Common.General.Comment')"                
              v-model="order.comment"
              :disabled="isUpdating"
            />

          </div>
        </div>

        <div class="flex-1 md:overflow-y-auto p-4">

          <div class="mb-1 text-sm" v-html="$tk('Common.General.RTI')"></div>          

          <PProductQuantitiesPicker
            v-model="productQuantities"
            :isExternal="true"            
            :showFill="false"         
            :showPrices="false"
            :showBalances="false"            
            :showQualities="false"
            :allowNegativeVolume="true"
            transactionType="TO"
          />          
        </div>
      </div>
    </div>
    
    <PDialogActions :split="deleteVisible">

      <PButton 
        color="danger" 
        icon="trash-alt"
        :disabled="isUpdating"
        :loading="isDeleting"
        @click="onDelete"
        v-if="deleteVisible"        
      >
        <span v-html="$tk('ExternalOrderDialog.DeleteOrder')"></span>
      </PButton>

      <PButtonGroup>
        
        <PButton 
          color="secondary" 
          @click="onClose"            
          :disabled="isUpdating"          
        >
          <span v-html="$tk('Common.Actions.Close')"></span>
        </PButton>

        <PButton
          color="secondary"
          @click="onSaveDraft"
          :loading="isSavingDraft"
          v-if="saveDraftVisible"          
          :disabled="!canSubmit || isUpdating"
        >
          <span v-html="$tk('Common.Actions.SaveDraft')"></span>
        </PButton>

        <PButton 
          color="primary" 
          @click="onSubmit"
          :loading="isSubmitting"          
          :disabled="!canSubmit || isUpdating"
        >
          <span v-html="$tk('Common.Actions.Send')"></span>
        </PButton>

      </PButtonGroup>

    </PDialogActions>
  </PDialog>
</template>

<script>

import http from "@/http"
import { mapGetters } from "vuex"
import PExternalLocationSelect from "@/components/forms/PExternalLocationSelect"
import PProductQuantitiesPicker from "@/components/forms/PProductQuantitiesPicker"
import {
  find,
  filter,
  get,
  forEach,  
  map,
  pick,
  some
} from "lodash"
import { format } from "date-fns"

const TRANSACTIONSTATUS_DRAFT = "Web"
const TRANSACTIONSTATUS_SENT = "Sent"

const PRODUCT_FIELDS = [
  "productId",
  "qualityId",
  "treatmentId",
  "quantity"
]

export default {
  
  name: "ExternalOrderDialog",

  components: {
    PExternalLocationSelect,
    PProductQuantitiesPicker
  },
  
  props: {
    orderId: {
      type: String,
      default: ""
    }  
  },
  
  data () {
    return {
      order: {
        id: "",
        externalOrderId: "",
        externalLocationKey: "",
        sendDate: "",
        sendersRef: "",
        receiversRef: "",
        comment: "",
        transactions: [],
        transactionStatus: ""
      },
      isLoading: false,
      isSavingDraft: false,
      isDeleting: false,
      isSubmitting: false,
    }
  },
  
  computed: {
    
    ...mapGetters([
      "customer",
      "location"
    ]),
    
    isDraft () {
      return get(this.order, "transactionStatus") === TRANSACTIONSTATUS_DRAFT
    },

    isSent () {
      return get(this.order, "transactionStatus") === TRANSACTIONSTATUS_SENT
    },

    isUpdating () {
      return this.isDeleting || 
             this.isSubmitting || 
             this.isSavingDraft
    },

    saveDraftVisible () {
      return !this.order.transactionStatus || this.isDraft
    },

    deleteVisible () {
      return this.orderId && (this.isDraft || this.isSent)
    },

    canSubmit () {      
      return !!this.order.sendDate &&
             !!this.order.externalLocationKey &&
            some(this.order.transactions, t => t.quantity !== 0)
    },

    productQuantities: {
      get () {
        return map(this.order.transactions, t => {
          return pick(t, PRODUCT_FIELDS)
        })        
      },
      set (value) {
        forEach(value, product => {          
          
          let transaction = find(this.order.transactions, 
            t => t.productId === product.productId)
          
          if (!transaction && product.quantity !== 0) {
            this.order.transactions.push(
              pick(product, PRODUCT_FIELDS)              
            )        
          } 
          else if (transaction) {
            transaction.quantity = product.quantity
          }
        })
      }
    }
  },

  methods: {

    onClose () {
      this.$emit("close")
    },

    async onSaveDraft () {
      this.isSavingDraft = true
      await this.saveOrder (TRANSACTIONSTATUS_DRAFT)
      this.isSavingDraft = false
    },

    async onSubmit () {
      this.isSubmitting = true
      await this.saveOrder (TRANSACTIONSTATUS_SENT)
      this.isSubmitting = false
    },

    async saveOrder (transactionStatus) {

      if (!this.order.id) {
        try {
          this.order.id = await http.post("ExternalOrderId")
        } catch (error) {
          this.displayError(error)
          return
        }
      }      

      let promises = []

      const postTransactions = filter(this.order.transactions, t => t.quantity !== 0)
      const deleteTransactions = filter(this.order.transactions, t => t.quantity === 0 && t.id)

      forEach(postTransactions, transaction => {
        promises.push(http.post("ExternalTransaction", {
          transactionStatus,
          externalOrderId: this.order.id,
          customerId: this.customer.id,
          locationIdFrom: this.location.id,
          ...pick(this.order, [            
            "externalLocationKey",
            "sendDate",
            "sendersRef",
            "receiversRef",
            "comment"            
          ]),
          ...pick(transaction, [
            "id",
            "productId",
            "quantity"
          ])
        }))
      })

      forEach(deleteTransactions, transaction => {
        promises.push(http.delete("ExternalTransaction", { 
          params: {
            id: transaction.id
          }
        }))
      })

      try {
        await Promise.all(promises)
      } catch (error) {
        this.displayError()
      }

      this.$emit("close")
    },
    
    async onDelete () {

      this.isDeleting = true

      try {
        await http.delete("ExternalOrder", { 
          params: {
            id: this.order.id
          }
        })
      } catch (error) {
        this.displayError(error)
      }

      this.isDeleting = false

      this.$emit("close")

    },

    displayError (error) {
      this.$store.dispatch("notify", {
        type: "negative",
        text: error.reason
      })
    }

  },

  async created () {

    this.isLoading = true
    
    if (this.orderId) {
      try {
        this.order = await http.get("ExternalOrder", { 
          params: { 
            id: this.orderId 
          }
        })        
      } catch (error) {
        this.displayError(error)
      }
    } else {
      this.order.sendDate = format(new Date(), "yyyy-MM-dd")
    }

    this.isLoading = false
    
  }
}

</script>
