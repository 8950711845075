<template>
  <div
    class="flex-1 flex flex-col overflow-hidden border bg-white border-gray-300 rounded"
  >
    <POrderGrid
      ref="ordergrid"
      :customerId="customer.id"
      :locationId="location.id"
      :locationIdFrom="location.id"
      :cols="cols"
      :expandAll="true"
      :filters="filters"
      method="ExternalOrders"
      @click="onClick"
    ></POrderGrid>

    <Portal to="filters">
      <POrderFilters v-model="filters" />
    </Portal>

    <Portal to="actions">
      
      <PButton 
        @click="importDialogVisible = true" 
        color="secondary" 
        icon="file-excel"        
        >
          <span v-html="$tk('Common.General.Import')"></span>
        </PButton>

      <PButton 
        class="ml-4"
        @click="externalOrderDialogVisible = true" 
        color="primary"        
      >
        <span v-html="$tk('External.NewDelivery')"></span>
      </PButton>

    </Portal>

     <ExternalOrderDialog 
        v-if="externalOrderDialogVisible" 
        :orderId="orderId"
        @close="onCreateClose" 
      />

      <ImportDialog 
        v-if="importDialogVisible"
        importType="External" 
        @close="onImportClose" 
      />

  </div>
</template>

<script>
  import { mapGetters } from "vuex"
  import { format, sub, add } from "date-fns"
  import ExternalOrderDialog from "../dialogs/ExternalOrderDialog"
  import ImportDialog from "@/areas/orders/dialogs/ImportDialog.vue"

  export default {
    
    name: "External",
    
    components: {
      ExternalOrderDialog,
      ImportDialog
    },
    
    computed: {
      ...mapGetters (["customer", "location"])
    },

    data () {
      return {
        cols: [        
          { text: this.$tk("Common.Order.OrderNo"), value: "id", sortable: false, component: "p-display-text", width: 110, align: "center", classes: "font-medium" },
          { text: this.$tk("Common.Order.Sent"), value: "sendDate", sortable: false, component: "p-display-date", width: 110, align: "center" },
          { text: this.$tk("Common.Order.To"), value: "externalLocationName", sortable: false, component: "p-display-text", align: "left" },
          { text: this.$tk("Common.Order.OrderRef"), value: "receiversRef", sortable: false, align: "left", component: "p-display-text" },
          { text: this.$tk("Common.Order.ReceiversRef2"), value: "sendersRef", sortable: false, align: "left", component: "p-display-text" },
          { 
            nested: [            
              { text: this.$tk("Common.Order.RTI"), component: "p-display-product", aggregate: "p-display-product-images", align: "left", width: 220 },            
              { text: this.$tk("Common.Order.Sent"), value: "quantity", component: "p-display-number", aggregate: "p-display-sum", width: 120, align: "right" },             
            ] 
          },        
          { text: this.$tk("Common.Order.Comments"), value: "comment", component: "p-display-text", width: 120, align: "center" },
          { text: this.$tk("Common.Order.Status"), component: "p-display-status", align: "center", width: 140},
        ],
        externalOrderDialogVisible: false,
        importDialogVisible: false,
        orderId: null,      
        expandAll: false,
        filters: {
          sendLower:        { value: format(sub(new Date(), { months: 1 }), "yyyy-MM-dd") },
          sendUpper:        { value: format(add(new Date(), { days: 1 }), "yyyy-MM-dd") },
          statuses: [
            { id: 0, value: true },            
            { id: 2, value: true, label: this.$tk("Common.OrderStatus.Sent") },
            { id: 3, value: false, label: this.$tk("Common.OrderStatus.Received") },
          ]   
        },    
        orderBy: {
          field: "sendDate",
          descending: false
        },    
      }
    },

    methods: {

      getOrders () {
        this.$refs.ordergrid.getOrders()     
      },

      onCreateClose () {            
        this.externalOrderDialogVisible = false
        this.orderId = null
        this.getOrders()
      },

      onClick ({ order }) {
        if (order.transactionStatusId === 0 || order.transactionStatusId === 2) {
          this.orderId = order.id
          this.externalOrderDialogVisible = true
        }
      },

      onImportClose () {
        this.importDialogVisible = false
        this.getOrders()
      },
    }
  }
</script>
